import * as Sentry from '@sentry/nuxt'

const config = useRuntimeConfig()
const router = useRouter()

Sentry.init({
  dsn: config.public.sentry.dsn,
  environment: config.public.sentry.environment,
  dist: String(config.public.sentry.dist),
  release: config.public.sentry.release,

  tracesSampleRate: 0.05,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
})
